define('ember-moment/helpers/moment-format', ['exports', 'ember', 'moment', 'ember-moment/utils/compute-fn'], function (exports, _ember, _moment, _emberMomentUtilsComputeFn) {
  exports['default'] = _ember['default'].Helper.extend({
    globalOutputFormat: 'LLLL',
    globalAllowEmpty: false,

    compute: (0, _emberMomentUtilsComputeFn['default'])(function (params, _ref) {
      var locale = _ref.locale;

      var length = params.length;

      if (length > 3) {
        throw new TypeError('ember-moment: Invalid Number of arguments, expected at most 3');
      }

      var output = undefined;
      var args = [];

      args.push(params[0]);

      if (length === 1) {
        output = this.globalOutputFormat;
      } else if (length === 2) {
        output = params[1];
      } else if (length > 2) {
        args.push(params[2]);
        output = params[1];
      }

      var time = _moment['default'].apply(undefined, args);

      if (locale) {
        time = time.locale(locale);
      }

      return time.format(output);
    })
  });
});