define('ember-moment/computeds/format', ['exports', 'ember', 'moment', 'ember-new-computed', 'ember-moment/utils/is-descriptor'], function (exports, _ember, _moment, _emberNewComputed, _emberMomentUtilsIsDescriptor) {
  var _get = _ember['default'].get;
  var assert = _ember['default'].assert;

  var a_slice = Array.prototype.slice;

  function computedFormat(date, maybeOutputFormat, maybeInputFormat) {
    assert('At least one datetime argument required for moment computed', arguments.length);

    var args = a_slice.call(arguments);
    var result = undefined;
    args.shift();

    return result = (0, _emberNewComputed['default'])(date, {
      get: function get() {
        var _this = this;

        var momentArgs = [_get(this, date)];

        var propertyValues = args.map(function (arg) {
          var desc = _emberMomentUtilsIsDescriptor['default'].call(_this, arg);
          if (desc && result._dependentKeys.indexOf(arg) === -1) {
            result.property(arg);
          }

          return desc ? _get(_this, arg) : arg;
        });

        if (propertyValues.length) {
          maybeOutputFormat = propertyValues[0];

          if (propertyValues.length > 1) {
            maybeInputFormat = propertyValues[1];
            momentArgs.push(maybeInputFormat);
          }
        } else if (this.container) {
          var config = this.container.lookupFactory('config:environment');
          maybeOutputFormat = _get(config, 'moment.ouputFormat');
        }

        return _moment['default'].apply(this, momentArgs).format(maybeOutputFormat);
      }
    });
  }

  exports['default'] = computedFormat;
});