define('ember-moment/helpers/moment-to-now', ['exports', 'ember', 'moment', 'ember-moment/utils/compute-fn'], function (exports, _ember, _moment, _emberMomentUtilsComputeFn) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var runBind = _ember['default'].run.bind;

  exports['default'] = _ember['default'].Helper.extend({
    globalAllowEmpty: false,

    compute: (0, _emberMomentUtilsComputeFn['default'])(function (params, _ref) {
      var hidePrefix = _ref.hidePrefix;
      var interval = _ref.interval;
      var locale = _ref.locale;

      this.clearTimer();

      if (interval) {
        this.timer = setTimeout(runBind(this, this.recompute), parseInt(interval, 10));
      }

      var time = _moment['default'].apply(undefined, _toConsumableArray(params));

      if (locale) {
        time = time.locale(locale);
      }

      return time.toNow(hidePrefix);
    }),

    clearTimer: function clearTimer() {
      clearTimeout(this.timer);
    },

    destroy: function destroy() {
      this.clearTimer();
      this._super.apply(this, arguments);
    }
  });
});