define('ember-moment/helpers/moment-duration', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports['default'] = _ember['default'].Helper.extend({
    compute: function compute(params, _ref) {
      var locale = _ref.locale;

      if (!params || params && params.length > 2) {
        throw new TypeError('ember-moment: Invalid Number of arguments, at most 2');
      }

      var time = _moment['default'].duration.apply(_moment['default'], _toConsumableArray(params));

      if (locale) {
        time = time.locale(locale);
      }

      return time.humanize();
    }
  });
});